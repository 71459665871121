// Leaflet is loaded via base.html so its plugins work
// require('leaflet'); ... require('leaflet.fullscreen');

const imageRoot = '/static/js/vendor/leaflet/images/';
const greyIconRoot = '/static/images/leaflet/grey-icon/';

// Custom icon to ensure image paths are correct on staging & production (somehow, there is no issue on local)
//
// If the path is incorrect, a marker seems to show twice on staging:
// - it's actually a marker and its shadow
// - the shadow image isn't found and replaced by the default image
const blueIcon = L.icon({
	iconUrl: `${imageRoot}marker-icon.png`,
	iconRetinaUrl: `${imageRoot}marker-icon-2x.png`,
	shadowUrl: `${imageRoot}marker-shadow.png`,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});

const greyIcon = L.icon({
	iconUrl: `${greyIconRoot}marker-icon.png`,
	iconRetinaUrl: `${greyIconRoot}marker-icon-2x.png`,
	shadowUrl: `${greyIconRoot}marker-shadow.png`,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});


$(document).ready(function () {
	// Regular maps - loading once the page is ready
	$('.js-leaflet-map').each(function (index, element) {
		const lat = parseFloat($(element).data('lat'));
		const lng = parseFloat($(element).data('lng'));
		const zoom = parseInt($(element).data('zoom'));
		const mapboxToken = $(element).data('mapbox-token');
		const otherItems = $(element).data('other-items');

		const map = L.map(element.id, {
			fullscreenControl: { pseudoFullscreen: false }
		}).setView([lat, lng], zoom);
		L.tileLayer(
			'https://api.tiles.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={mapboxToken}',
			{
				tileSize: 512,
				maxZoom: 18,
				zoomOffset: -1,
				attribution: null,
				id: 'mapbox/streets-v11',
				mapboxToken
			}
		).addTo(map);

		L.marker([lat, lng], {icon: blueIcon}).addTo(map);
	});

	// remove Leaflet mention
	$('.leaflet-control-attribution').hide();
});



window.showArtworkMap = function() {
	// Artwork map is loaded when opening the corresponding popup to limit API usage
	$('.js-leaflet-artwork-map').each(function (index, element) {
		const lat = parseFloat($(element).data('lat'));
		const lng = parseFloat($(element).data('lng'));
		const zoom = parseInt($(element).data('zoom'));
		const mapboxToken = $(element).data('mapbox-token');
		const otherItems = $(element).data('other-items');

		const map = L.map(element.id, {
			fullscreenControl: { pseudoFullscreen: false }
		}).setView([lat, lng], zoom);
		L.tileLayer(
			'https://api.tiles.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={mapboxToken}',
			{
				tileSize: 512,
				maxZoom: 18,
				zoomOffset: -1,
				attribution: null,
				id: 'mapbox/streets-v11',
				mapboxToken
			}
		).addTo(map);

		L.marker([lat, lng], {icon: blueIcon}).addTo(map);

		// if provided, show other items in grey and goes to their corresponding URL on click
		if (otherItems !== undefined && otherItems !== null) {
			otherItems.forEach(item => {
				L.marker(
					[item.lat, item.lng],
					{icon: greyIcon})
					.addTo(map)
					.on('click', function(e) {
						document.location.href = item.url;
					});
			});
		}
	});

	// remove Leaflet mention
	$('.leaflet-control-attribution').hide();
};
